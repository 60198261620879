import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49d21eed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vaibe-logo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "main-nav" }
const _hoisted_5 = { class: "main-nav__link-icon" }
const _hoisted_6 = { class: "main-nav__link-label" }
const _hoisted_7 = { class: "main-nav__link-icon" }
const _hoisted_8 = { class: "main-nav__link-label" }
const _hoisted_9 = { class: "main-nav__link-icon" }
const _hoisted_10 = { class: "main-nav__link-label" }
const _hoisted_11 = { class: "main-nav__link-icon" }
const _hoisted_12 = { class: "main-nav__link-label" }
const _hoisted_13 = { class: "app-layout-page__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProductTourNav = _resolveComponent("ProductTourNav")!
  const _component_SettingsNav = _resolveComponent("SettingsNav")!
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh lpR fFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_drawer, {
        modelValue: _ctx.leftDrawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.leftDrawer) = $event)),
        "show-if-above": "",
        width: 257,
        breakpoint: 0,
        mini: _ctx.miniState,
        "mini-width": 64
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                (!_ctx.miniState)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.vaibeLogo,
                      alt: "Vaibe",
                      class: "vaibe-logo--normal"
                    }, null, 8, _hoisted_2))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.vaibeLogoSmall,
                      alt: "Vaibe",
                      class: "vaibe-logo--small"
                    }, null, 8, _hoisted_3))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("nav", _hoisted_4, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationLinks, (link, i) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  id: link.id,
                  key: i,
                  to: link.to,
                  "data-testid": link.testId,
                  "active-class": "main-nav__link--active",
                  class: "main-nav__link"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon)))
                    ]),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(link.labelKey)), 1)
                  ]),
                  _: 2
                }, 1032, ["id", "to", "data-testid"]))
              }), 128))
            ]),
            _createElementVNode("div", null, [
              (_ctx.isFTXEnabled)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    id: "vaibe-ftx__product-tour",
                    to: "",
                    "data-testid": "nav-settings-link",
                    class: "main-nav__link",
                    onClick: _ctx.startJoyride,
                    onKeypress: _ctx.startJoyride
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_ProductTourNav, { class: "app-layout-nav-icon" })
                      ]),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('nav.productTour')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "onKeypress"]))
                : _createCommentVNode("", true),
              (_ctx.showSettings)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    id: "vaibe-ftx__settings",
                    to: "/settings",
                    "data-testid": "nav-settings-link",
                    "active-class": "main-nav__link--active",
                    class: "main-nav__link"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_SettingsNav, { class: "app-layout-nav-icon" })
                      ]),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('nav.settings')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_router_link, {
                id: "vaibe-ftx__profile",
                to: "/account-settings",
                class: _normalizeClass([
              'main-nav__link',
              {
                'main-nav__link--active': _ctx.isProfileSelected,
              },
            ])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_AppAvatar, {
                      class: _normalizeClass([
                  'profile-dropdown__avatar',
                  {
                    'profile-dropdown__avatar--active': _ctx.isProfileSelected,
                  },
                ])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.avatarInitials), 1)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.name), 1)
                  ])
                ]),
                _: 1
              }, 8, ["class"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "mini"]),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}