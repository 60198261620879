import axios from '@/plugins/http/axios';
import {
  NewPublicDisplay,
  PinInformation,
  PublicDisplay,
} from '@/shared/types/publicDisplays';
import { ApiResponse } from '@/shared/types/generic';

export default {
  getPinInformation(): Promise<PinInformation> {
    return axios.get('/management/public-displays');
  },

  editPin(pin: string): Promise<{ message: string }> {
    return axios.post('/management/public-displays/edit/pin', { pin });
  },

  getRandomPinInformation(): Promise<PinInformation> {
    return axios.get('/management/public-displays/random/pin');
  },

  togglePinPublicDisplays(activePin: boolean): Promise<void> {
    return axios.put('/management/public-displays/active-pin', { activePin });
  },

  getPublicDisplaysList(): Promise<ApiResponse<PublicDisplay[]>> {
    return axios.get('/management/public-displays/displays');
  },

  createPublicDisplay(publicDisplay: NewPublicDisplay): Promise<void> {
    return axios.post('/management/public-displays', publicDisplay);
  },
};
