/* istanbul ignore file */

import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { StoreType } from '@/shared/types/store';
import useTokenStore from '@/store/token/useTokenStore';
import { isEmpty } from '@/shared/helpers/validators/validators';
import useProfileStore from '@/store/profile/useProfileStore';
import {
  Feature,
  RouteAuthenticationRequirement,
  Routes,
} from '@/shared/types/generic';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import { SurveyWizardStep } from '@/shared/types/surveys';
import useFeatures from '@/composables/useFeatures/useFeatures';
import useRolesStore from '@/store/roles/useRolesStore';
import { PermissionCode } from '@/shared/types/roles';
import useNavMenuData from '@/composables/useNavMenuData/useNavMenuData';
import useRoleLevelPermissions from '@/composables/useRoleLevelPermissions/useRoleLevelPermissions';

const { isFeatureEnabled, loadFeatures } = useFeatures();

function preventDirectRouteChange(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  destination = '/',
) {
  if (from.name || to.query.bypass === 'true') {
    next();
  } else {
    next(destination);
  }
}

function preventRouteChange(
  from: RouteLocationNormalized,
  filteredPath: string,
  toPath: string,
  next: NavigationGuardNext,
) {
  if (from.fullPath.includes(filteredPath)) {
    next();
  } else {
    next(toPath);
  }
}

const Users = () =>
  import(/* webpackChunkName: "users" */ '@/views/UsersView/UsersView.vue');
const UsersTable = () =>
  import(
    /* webpackChunkName: "users" */ '@/components/user/UsersTable/UsersTable.vue'
  );
const ManagersTable = () =>
  import(
    /* webpackChunkName: "users" */ '@/components/user/ManagersTable/ManagersTable.vue'
  );
const TeamsList = () =>
  import(
    /* webpackChunkName: "users" */ '@/components/user/TeamsList/TeamsList.vue'
  );
const Leaderboard = () =>
  import(
    /* webpackChunkName: "leaderboard" */ '@/views/LeaderboardView/LeaderboardView.vue'
  );
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ '@/views/DashboardView/DashboardView.vue'
  );
const Challenges = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/views/ChallengesView/ChallengesView.vue'
  );
const ProfileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileView/ProfileView.vue'
  );

const EditProdileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/EditProfileView/EditProfileView.vue'
  );

const SecurityView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/SecurityView/SecurityView.vue'
  );

const SecurityDetailsView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/SecurityDetailsView/SecurityDetailsView.vue'
  );

const AccountSettingsView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AccountSettingsView/AccountSettingsView.vue'
  );

const DeleteAccountView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/DeleteAccountView/DeleteAccountView.vue'
  );

const AccountSettingsChangePasswordView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AccountSettingsChangePasswordView/AccountSettingsChangePassword.vue'
  );

const SettingsView = () =>
  import(
    /* webpackChunkName: "settings" */ '@/views/SettingsView/SettingsView.vue'
  );

const MetricsView = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/views/MetricsView/MetricsView.vue'
  );

const SitesList = () =>
  import(
    /* webpackChunkName: "settings" */ '@/components/settings/sites/SitesList/SitesList.vue'
  );

const MetricsTable = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/components/metrics/MetricsTable/MetricsTable.vue'
  );
const CategoriesList = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/components/metrics/CategoriesList/CategoriesList.vue'
  );
const MetricsOverview = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/views/MetricsOverview/MetricsOverview.vue'
  );
const AddMetric = () =>
  import(
    /* webpackChunkName: "add-metrics" */ '@/views/AddMetricView/AddMetricView.vue'
  );
const Store = () =>
  import(/* webpackChunkName: "store" */ '@/views/StoreView/StoreView.vue');
const AddStoreItem = () =>
  import(
    /* webpackChunkName: "store" */ '@/views/AddStoreItemsView/AddStoreItemsView.vue'
  );
const DataInput = () =>
  import(
    /* webpackChunkName: "data-input" */ '@/views/DataInputView/DataInputView.vue'
  );
const AddDataInputView = () =>
  import(
    /* webpackChunkName: "data-input" */ '@/views/AddDataInputView/AddDataInputView.vue'
  );
const NoConnectionView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/NoConnectionView/NoConnectionView.vue'
  );
const MaintenanceView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/MaintenanceView/MaintenanceView.vue'
  );
const LoginView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/LoginView/LoginView.vue'
  );
const ForgotPasswordView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/ForgotPasswordView/ForgotPasswordView.vue'
  );
const ChangePasswordView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/ChangePasswordView/ChangePasswordView.vue'
  );
const FarewellView = () =>
  import(
    /* webpackChunkName: "unauthenticated" */ '@/views/FarewellView/FarewellView.vue'
  );
const AddUser = () =>
  import(
    /* webpackChunkName: "metrics" */ '@/views/AddUserView/AddUserView.vue'
  );
const SurveysView = () =>
  import(
    /* webpackChunkName: "surveys" */ '@/views/SurveysView/SurveysView.vue'
  );
const SurveysTable = () =>
  import(
    /* webpackChunkName: "surveys" */ '@/components/surveys/SurveysTable/SurveysTable.vue'
  );
const SurveysWizard = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysWizard/SurveysWizard.vue'
  );
const SurveysStepId = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepId/SurveysStepId.vue'
  );
const SurveysStepDuration = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepDuration/SurveysStepDuration.vue'
  );
const SurveysStepQuestions = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepQuestions/SurveysStepQuestions.vue'
  );
const SurveysStepSummary = () =>
  import(
    /* webpackChunkName: "surveys-wizard" */ '@/components/surveys/SurveysStepSummary/SurveysStepSummary.vue'
  );
const IconsView = () =>
  import(/* webpackChunkName: "icons" */ '@/views/IconsView/IconsView.vue');

const ChallengeWizard = () =>
  import(
    /* webpackChunkName: "challenges-wizard" */ '@/components/challenges/ChallengeWizard/ChallengeWizardView/ChallengeWizardView.vue'
  );

const ChallengesTable = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/components/challenges/ChallengesTable/ChallengesTable.vue'
  );
const PublicDisplaysSwapperView = () =>
  import(
    /* webpackChunkName: "public-displays" */ '@/views/PublicDisplaysSwapperView/PublicDisplaysSwapperView.vue'
  );
const PublicDisplaysList = () =>
  import(
    /* webpackChunkName: "public-displays" */ '@/components/publicDisplays/PublicDisplaysList/PublicDisplaysList.vue'
  );
const AddPublicDisplaysView = () =>
  import(
    /* webpackChunkName: "public-displays" */ '@/views/AddPublicDisplaysView/AddPublicDisplaysView.vue'
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'main',
    redirect: Routes.HOME,
  },
  {
    path: Routes.FIRST_LOGIN,
    name: 'first-login',
    redirect: Routes.CHALLENGES,
    beforeEnter: () => {
      const tokenStore = useTokenStore();

      tokenStore.setToken('', '', '');
    },
  },
  {
    path: Routes.ICONS,
    name: 'icons',
    component: IconsView,
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV !== 'development') {
        next({ path: '/' });
      }
      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: Routes.LOGIN,
    name: 'login',
    component: LoginView,
  },
  {
    path: Routes.FAREWELL,
    name: 'farewell',
    component: FarewellView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
    },
  },

  {
    path: Routes.FORGOT_PASSWORD,
    name: 'forgot-password',
    component: ForgotPasswordView,
  },
  {
    path: Routes.CHANGE_PASSWORD,
    name: 'change-password',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
    },
    component: ChangePasswordView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
  {
    path: Routes.HOME,
    name: 'home',
    component: Dashboard,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      permissionCode: PermissionCode.DASHBOARD_READ,
    },
  },
  {
    path: Routes.USERS,
    name: 'users',
    component: Users,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        name: 'usersTable',
        component: UsersTable,
        beforeEnter: (to, from, next) => {
          const rolesStore = useRolesStore();
          if (!rolesStore.isPermissionActive(PermissionCode.USERS_READ)) {
            next(Routes.USERS_TEAMS);
          } else {
            next();
          }
        },
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.USERS_TEAMS,
        name: 'teams-list',
        component: TeamsList,
        meta: {
          firstLevel: true,
          permissionCode: PermissionCode.TEAMS_READ,
        },
      },
      {
        path: Routes.USERS_ADD,
        name: 'addUser',
        component: AddUser,
        meta: {
          miniMode: true,
          permissionCode: PermissionCode.USERS_CREATE,
        },
      },
      {
        path: Routes.USERS_MANAGERS,
        name: 'managers-table',
        component: ManagersTable,
        meta: {
          firstLevel: true,
          permissionCode: PermissionCode.USERS_READ,
        },
      },
    ],
  },
  {
    path: Routes.LEADERBOARD,
    name: 'leaderboard',
    component: Leaderboard,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      permissionCode: PermissionCode.LEADERBOARD_READ,
    },
  },
  {
    path: Routes.METRICS,
    name: 'metrics',
    redirect: Routes.METRICS_OVERVIEW,
    component: MetricsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: Routes.METRICS_TABLE,
        name: 'metrics-table',
        component: MetricsTable,
        beforeEnter: (to, from, next) => {
          const rolesStore = useRolesStore();
          if (!rolesStore.isPermissionActive(PermissionCode.METRICS_READ)) {
            next(Routes.METRICS_CATEGORIES);
          } else {
            next();
          }
        },
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.METRICS_OVERVIEW,
        name: 'metrics-overview',
        component: MetricsOverview,
        beforeEnter: (to, from, next) => {
          const rolesStore = useRolesStore();
          if (!rolesStore.isPermissionActive(PermissionCode.METRICS_READ)) {
            next(Routes.METRICS_CATEGORIES);
          } else if (!isFeatureEnabled(Feature.METRIC_OVERVIEW_VISIBILITY)) {
            next({ path: Routes.METRICS_TABLE });
          } else {
            next();
          }
        },
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.METRICS_CATEGORIES,
        name: 'categories-list',
        component: CategoriesList,
        meta: {
          firstLevel: true,
          permissionCode: PermissionCode.CATEGORY_READ,
        },
      },
      {
        path: Routes.METRICS_ADD,
        name: 'addMetric',
        component: AddMetric,
        meta: {
          miniMode: true,
          authenticationRequirement:
            RouteAuthenticationRequirement.AUTHENTICATED,
          permissionCode: PermissionCode.METRICS_CREATE,
        },
      },
    ],
  },
  {
    path: Routes.ACCOUNT_SETTINGS,
    name: 'accountSettings',
    component: AccountSettingsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },

    children: [
      {
        path: '',
        name: 'profile',
        component: ProfileView,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.ACCOUNT_SETTINGS_EDIT_PROFILE,
        name: 'account-settings',
        component: EditProdileView,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.ACCOUNT_SETTINGS_SECURITY,
        name: 'security',
        component: SecurityView,
        meta: {
          firstLevel: true,
        },
        children: [
          {
            path: '',
            name: 'securityDetails',
            component: SecurityDetailsView,
            meta: {
              firstLevel: true,
            },
          },
          {
            path: Routes.ACCOUNT_SETTINGS_SECURITY_DELETE_ACCOUNT,
            name: 'deleteAccount',
            component: DeleteAccountView,
            meta: {
              firstLevel: true,
            },
          },
          {
            path: Routes.ACCOUNT_SETTINGS_SECURITY_CHANGE_PASSWORD,
            name: 'changePassword',
            beforeEnter: (to, from, next) =>
              preventDirectRouteChange(from, to, next),
            component: AccountSettingsChangePasswordView,
            meta: {
              firstLevel: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: Routes.SETTINGS,
    name: 'settings',
    redirect: Routes.SETTINGS_SITES,
    component: SettingsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: Routes.SETTINGS_SITES,
        name: 'sites-list',
        component: SitesList,
        meta: {
          firstLevel: true,
          permissionCode: PermissionCode.SITES_READ,
        },
      },
    ],
  },
  {
    path: Routes.CHALLENGES,
    name: 'games',
    component: Challenges,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      permissionCode: PermissionCode.CHALLENGE_READ,
    },
    children: [
      {
        path: '',
        name: 'gamesTable',
        component: ChallengesTable,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.CHALLENGES_ADD,
        name: 'addChallenge',
        component: ChallengeWizard,
        meta: {
          miniMode: true,
          permissionCode: PermissionCode.CHALLENGE_CREATE,
        },
      },
    ],
  },
  {
    path: Routes.STORE,
    name: 'store',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: '',
        alias: 'prizes',
        name: 'prizes',
        component: Store,
        beforeEnter: (to, from, next) => {
          const rolesStore = useRolesStore();
          if (!rolesStore.isPermissionActive(PermissionCode.STORE_READ_ITEMS)) {
            next(Routes.STORE_PURCHASE_ACTIVITY);
          } else {
            next();
          }
        },
        meta: {
          firstLevel: true,
          storeType: StoreType.Prize as StoreType,
        },
      },
      {
        path: Routes.STORE_COLLECTABLES,
        name: 'collectables',
        component: Store,
        meta: {
          firstLevel: true,
          storeType: StoreType.Collectable as StoreType,
          permissionCode: PermissionCode.STORE_READ_ITEMS,
        },
      },
      {
        path: Routes.STORE_PURCHASE_ACTIVITY,
        name: 'purchase-activity',
        component: Store,
        meta: {
          firstLevel: true,
          storeType: StoreType.PurchaseActivity as StoreType,
          permissionCode: PermissionCode.PURCHASE_ACTIVITY_READ,
        },
      },
      {
        path: Routes.STORE_ADD,
        name: 'addStoreItem',
        component: AddStoreItem,
        meta: {
          miniMode: true,
          permissionCode: PermissionCode.STORE_CREATE_PRIZE,
        },
      },
    ],
  },
  {
    path: Routes.DATA_IMPORT,
    name: 'dataInput',
    component: DataInput,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      permissionCode: PermissionCode.DATA_IMPORT_READ,
    },
    children: [
      {
        path: Routes.DATA_IMPORT_ADD,
        component: AddDataInputView,
      },
    ],
  },
  {
    path: Routes.PUBLIC_DISPLAYS,
    name: 'publicDisplays',
    component: PublicDisplaysSwapperView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      permissionCode: PermissionCode.PUBLIC_DISPLAYS_READ,
    },
    children: [
      {
        path: '',
        name: 'publicDisplaysList',
        component: PublicDisplaysList,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: 'add',
        name: 'addPublicDisplays',
        component: AddPublicDisplaysView,
        meta: {
          miniMode: true,
        },
      },
    ],
  },
  {
    path: Routes.SURVEYS,
    name: 'surveys',
    component: SurveysView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      permissionCode: PermissionCode.SURVEYS_READ,
    },
    children: [
      {
        path: '',
        name: 'surveysTable',
        component: SurveysTable,
        meta: {
          firstLevel: true,
        },
      },
      {
        path: Routes.SURVEYS_ADD,
        name: 'addSurveys',
        component: SurveysWizard,
        redirect: Routes.SURVEYS_ADD_ID,
        meta: {
          miniMode: true,
          permissionCode: PermissionCode.SURVEYS_CREATE,
        },
        children: [
          {
            path: Routes.SURVEYS_ADD_ID,
            component: SurveysStepId,
            meta: {
              stepTitle: 'surveys.wizard.steps.id.title',
              step: SurveyWizardStep.ID,
            },
          },
          {
            path: Routes.SURVEYS_ADD_DURATION,
            component: SurveysStepDuration,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.SURVEYS_ADD,
                Routes.SURVEYS_ADD_ID,
                next,
              ),
            meta: {
              stepTitle: 'surveys.wizard.steps.duration.title',
              step: SurveyWizardStep.DURATION,
            },
          },
          {
            path: Routes.SURVEYS_ADD_QUESTIONS,
            component: SurveysStepQuestions,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.SURVEYS_ADD,
                Routes.SURVEYS_ADD_ID,
                next,
              ),
            meta: {
              stepTitle: 'surveys.wizard.steps.questions.title',
              step: SurveyWizardStep.QUESTIONS,
            },
          },
          {
            path: Routes.SURVEYS_ADD_SUMMARY,
            component: SurveysStepSummary,
            beforeEnter: (_to, from, next) =>
              preventRouteChange(
                from,
                Routes.SURVEYS_ADD,
                Routes.SURVEYS_ADD_ID,
                next,
              ),
            meta: {
              stepTitle: 'surveys.wizard.steps.summary.title',
              step: SurveyWizardStep.SUMMARY,
            },
          },
        ],
      },
    ],
  },
  {
    path: Routes.ERROR_502,
    name: 'no-connection',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: NoConnectionView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
  {
    path: Routes.ERROR_503,
    name: 'maintenance',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: MaintenanceView,
    beforeEnter: (to, from, next) => preventDirectRouteChange(from, to, next),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const tokenStore = useTokenStore();
  const rolesStore = useRolesStore();
  const profileStore = useProfileStore();
  const translationsStore = useTranslationsStore();

  const routeRequiresAuthentication = to.matched.some(
    (route) =>
      route.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );

  const routeHasUniversalAccess =
    to.meta.authenticationRequirement ===
    RouteAuthenticationRequirement.UNIVERSAL;

  let isAuthenticated = !isEmpty(tokenStore.token);

  const profileIsNotLoaded = profileStore.userProfile === undefined;

  if (!(to.name === 'maintenance' || to.name === 'no-connection')) {
    if (isAuthenticated && profileIsNotLoaded) {
      try {
        await loadFeatures();

        const promises: Promise<void>[] = [
          profileStore.loadProfile(),
          profileStore.loadProfileSite(),
          useRoleLevelPermissions().loadRoleLevel(),
        ];

        if (process.env.NODE_ENV !== 'development') {
          promises.push(translationsStore.loadTranslations());
        }

        await Promise.all(promises);
      } catch (e) {
        profileStore.userProfile = undefined;
      }

      isAuthenticated = !isEmpty(tokenStore.token);
    }

    if (
      !routeRequiresAuthentication &&
      !routeHasUniversalAccess &&
      isAuthenticated
    ) {
      next({ path: '/', query: { bypass: 'true' } });

      return;
    }

    if (
      routeRequiresAuthentication &&
      !isAuthenticated &&
      !routeHasUniversalAccess
    ) {
      next({ path: Routes.LOGIN, query: { bypass: 'true' } });

      return;
    }
  }

  if (
    to.meta.permissionCode &&
    !rolesStore.isPermissionActive(to.meta.permissionCode as PermissionCode)
  ) {
    next(useNavMenuData().getNextAvailableNavLink());
    return;
  }

  next();
});

router.afterEach((to) => {
  if (to.name === 'change-password') {
    const historyObject = { page: 'change-password' };
    window.history.pushState(historyObject, '', to.path);
  }
  if (to.query?.bypass === 'true') {
    router.replace({ ...to, query: undefined });
  }
});

export default router;
