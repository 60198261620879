import rolesService from '@/shared/services/rolesService/rolesService';
import {
  PermissionCode,
  ProfileRoleLevel,
  RoleLevelsSimplified,
} from '@/shared/types/roles';
import { RoleTypeStaticReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export interface RoleStoreState {
  roleLevelsSimplified: RoleLevelsSimplified;
  profileRoleLevel: ProfileRoleLevel;
}

export default defineStore('roles', () => {
  const state = reactive<RoleStoreState>({
    profileRoleLevel: {
      name: '',
      activePermissionsCodes: {} as Record<PermissionCode, boolean>,
    },

    roleLevelsSimplified: {
      defaultRoleLevels: [],
      customRoleLevels: [],
    },
  });

  function isPermissionActive(permissionCode: PermissionCode): boolean {
    return state.profileRoleLevel.activePermissionsCodes[permissionCode];
  }

  async function loadProfileRoleLevel(): Promise<void> {
    const { name, activePermissionsCodes } =
      await rolesService.getProfileRoleLevels();

    state.profileRoleLevel.name = name;
    state.profileRoleLevel.activePermissionsCodes = {} as Record<
      PermissionCode,
      boolean
    >;
    activePermissionsCodes.forEach((permissionCode: PermissionCode) => {
      state.profileRoleLevel.activePermissionsCodes[permissionCode] = true;
    });
  }

  async function loadRoleLevelsSimplified(
    organizationName?: string,
  ): Promise<void> {
    const { defaultRoleLevels, customRoleLevels } =
      await rolesService.getRoleLevelsSimplified(
        RoleTypeStaticReference.MANAGER,
        organizationName,
      );
    state.roleLevelsSimplified.defaultRoleLevels = defaultRoleLevels;
    state.roleLevelsSimplified.customRoleLevels = customRoleLevels;
  }

  return {
    state,
    loadRoleLevelsSimplified,
    loadProfileRoleLevel,
    isPermissionActive,
  };
});
