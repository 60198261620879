import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import { PublicDisplay } from '@/shared/types/publicDisplays';

const publicDisplaysList = [
  {
    name: 'pd1',
    description: 'desc-pd1',
    displayCode: '001',
    language: 'en-GB',
    activePin: true,
    layoutType: 'all_challenges',
  },
  {
    name: 'um public display com um nome muitoooooooooooo grandeeeeeee e ainda maior que istooooooo',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eget magna lacus. Aliquam vitae lacus odio. Praesent vel orci tortor nullam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eget magna lacus. Aliquam vitae lacus odio. Praesent vel orci tortor nullam.',
    displayCode: '001',
    language: 'en-GB',
    activePin: false,
    layoutType: 'all_challenges',
  },
] as PublicDisplay[];

const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export const mockGetPublicDisplays = (): ApiResponse<PublicDisplay[]> => ({
  metadata,
  results: publicDisplaysList,
});

export const createPublicDisplay = () => {
  publicDisplaysList.push();
  return {
    language: 'PT_pt',
    results: publicDisplaysList,
  };
};
