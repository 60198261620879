import axios from '@/plugins/http/axios';
import { RoleTypeStaticReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import {
  ProfileRoleLevelResponse,
  RoleLevelsSimplified,
} from '@/shared/types/roles';

export default {
  getProfileRoleLevels(): Promise<ProfileRoleLevelResponse> {
    return axios.get('/profiles/role-levels-permissions');
  },

  getRoleLevelsSimplified(
    roleTypeStaticReference: RoleTypeStaticReference,
    organizationName?: string,
  ): Promise<RoleLevelsSimplified> {
    return axios.get('/role-levels/simplified', {
      params: {
        organization_name: organizationName,
        role_level_user_type_static_reference: roleTypeStaticReference,
      },
    });
  },
};
